<template>
    <div>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto fill-height" min-height="280" outlined>
                    <span>Vielen Dank für Ihre Anmeldung. (Bitte warten Sie einen kurzen Moment. Sie werden gleich weitergeleitet.)</span>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <!--<v-row>
        <pre>{{
          JSON.stringify($store.state.contract.aktuellerVertrag, null, 2)
        }}</pre>
      </v-row>-->
    </div>
</template>
  
<script>
import { httpsCallable } from "firebase/functions"
import { functions } from "@/plugins/firebase"

export default {
    components: {},
    data() {
        return {
            status: ""
        }
    },
    methods: {},
    computed: {},
    watch: {},
    async mounted() {
        if (loginNew.isSignedIn()) {
            if (this.$route.query.id) {
                const register = httpsCallable(functions, "register")
                const response = await register({
                    id: this.$route.query.id,
                    token: loginNew.getIdToken()
                })
                if (response.data.response == "ok") {
                    location.href = "/"
                } else {
                    console.warn(response.data)
                    // Fehlermeldung anzeigen
                }
            } else {
                this.$router.push("/error")
            }
        } else {
            loginNew.auth(`register:${this.$route.query.id}`)
        }
    }
}
</script>