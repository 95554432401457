var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto fill-height",
                  attrs: { "min-height": "280", outlined: "" },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Vielen Dank für Ihre Anmeldung. (Bitte warten Sie einen kurzen Moment. Sie werden gleich weitergeleitet.)"
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }